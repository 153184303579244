import React from 'react'
import { Link, graphql } from 'gatsby'

import { MarkdownRemarkConnection, MarkdownRemarkGroupConnection } from '@types/graphql-types'

import IndexLayout from '@/layouts/index'
import {
  Image
} from '@/components/atoms'
import {
  SEO,
  CategorySubCategoryList,
  TagsWithCount
} from '@/components/molecules'
import { SearchConnect } from '@/components/connects'
import * as styles from './404.module.scss'

type Props = {
  data: {
    allMarkdownRemark: MarkdownRemarkConnection
    tags: { group: MarkdownRemarkGroupConnection[] }
    category: { group: MarkdownRemarkGroupConnection[] }
    thumbnail: any
  }
}

const NotFound: React.FC<Props> = ({ data }) => {
  const { category, tags, thumbnail } = data
  const thumbnailPath = thumbnail.childImageSharp.resize.src
  const image = {
    publicURL: thumbnail.publicURL,
    childImageSharp: thumbnail.childImageSharp
  }

  return (
    <IndexLayout>
      <SEO type={"website"} thumbnailPath={thumbnailPath} />
      <SearchConnect>
        <div className={styles.root}>
          <div className={styles.thumbnail_root}>
            <Image className={styles.thumbnail} image={image} />
          </div>
          <div className={styles.content_root}>
            <p>
              お探しのページは「すでに削除されている」か「アクセスしたアドレスが異なっている」
              などの理由で見つかりませんでした。以下でキーワードやカテゴリー、タグから記事を探すことができます。
            </p>
            <p>
              以下のカテゴリー・タグ一覧から記事を探すことができます。
            </p>
            <div className={styles.category_root}>
              <span className={styles.category_title}>カテゴリ一</span>
              <CategorySubCategoryList className={styles.category_content} category={category} />
            </div>
            <div className={styles.tags_root}>
              <span className={styles.tags_title}>タグ</span>
              <TagsWithCount className={styles.tags_content} tags={tags} />
            </div>
            <div className={styles.home_btn_root}>
              <Link className={styles.home_btn} to={"/"}>ホームに戻る</Link>
            </div>
          </div>
        </div>
      </SearchConnect>
    </IndexLayout>
  )
}

export const NotFoundQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___createdOn], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            createdOn
            updatedOn
            image {
              publicURL
              childImageSharp {
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
          excerpt
        }
      }
    }
    tags: allMarkdownRemark {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    category: allMarkdownRemark {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
    thumbnail: file(relativePath: { eq: "404.png"}) {
      publicURL
      childImageSharp {
        resize(width: 1200, quality: 90) {
          src
        }
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
  }
`

export default NotFound
