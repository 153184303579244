// extracted by mini-css-extract-plugin
export var category_content = "_404-module--category_content--2x6jj";
export var category_root = "_404-module--category_root--3d4Uk";
export var category_title = "_404-module--category_title--EnEBY";
export var content_root = "_404-module--content_root--1hZrk";
export var home_btn = "_404-module--home_btn--Ph3ZY";
export var home_btn_root = "_404-module--home_btn_root--tTBOl";
export var root = "_404-module--root--32BeH";
export var tags_content = "_404-module--tags_content--2jPLV";
export var tags_root = "_404-module--tags_root--1O7PU";
export var tags_title = "_404-module--tags_title--2lIHw";
export var thumbnail = "_404-module--thumbnail---KIi7";
export var thumbnail_root = "_404-module--thumbnail_root--305Sw";